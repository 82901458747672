import React, { useRef, useState } from "react";
import { Button } from "../Button";
import Modal from "@/components/Modal";
import ViewDemoModal from "@/components/Modal/ViewDemoModal";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import Image from "next/legacy/image";

import FeatureData from "@/mocks/guestFeature";
import Questions from "modules/FeaturesModule/Component/Questions";

import Logo from "@/components/Logo";
import getStarttedButton from "../../../../public/legal-Images/webpImages/getStarttedButton.webp";
import contactUsButton from "../../../../public/legal-Images/webpImages/contactUsButton.webp";

import AppScreens from "../../../../public/legal-Images/webpImages/AppScreens.webp";
import AvatarGroup from "../../../../public/legal-Images/webpImages/AvatarGroup.webp";
import howItWorkCards from "../../../../public/legal-Images/webpImages/howItWorkCards.webp";
import tickLogo from "../../../../public/legal-Images/webpImages/tickLogo.webp";
import FrameAiPowered from "../../../../public/legal-Images/webpImages/FrameAiPowered.webp";
import UsersListFrame from "../../../../public/legal-Images/webpImages/user-list-frame.webp";
// import UsersListFrame from "../../../../public/images/pro";
import videoFrame2 from "../../../../public/legal-Images/webpImages/videoFrame2.webp";
// import PricingContent from "../../../../public/legal-Images/webpImages/pricing-content-frame.webp";
// import CTAcontent from "../../../../public/landing-images/Bigtab/CTAcontent4x.webp";
import LargeScreenBanner from "../../../../public/landing-images/LargeScreen/LargeScreenBanner.png";
import LargeScreen1 from "../../../../public/landing-images/LargeScreen/LargeScreen1.png";
import LargeScreen2 from "../../../../public/landing-images/LargeScreen/LargeScreen2.png";
import LargeScreen3 from "../../../../public/landing-images/LargeScreen/LargeScreen3.png";
import cardsCleanDesign from "../../../../public/legal-Images/webpImages/cardsCleanDesign.webp";
import cardspoweredByAi from "../../../../public/legal-Images/webpImages/cardspoweredByAi.webp";
import cardsprofessional from "../../../../public/legal-Images/webpImages/cardsprofessional.webp";
import getStarttedForFreeButton from "../../../../public/legal-Images/webpImages/getStarttedForFreeButton.webp";
import howItWorkButton from "../../../../public/legal-Images/webpImages/howItWorkButton.webp";
import securityFirstButton from "../../../../public/legal-Images/webpImages/securityFirstButton.png";
import playVideoButton from "../../../../public/legal-Images/webpImages/playVideoButton.webp";
import pricingButton from "../../../../public/legal-Images/webpImages/pricingButton.webp";

import DocumentAnalysis from "public/doc-processing-images/interactive-document-analysis.webp";
import MultipleFormats from "public/doc-processing-images/multiple-formats.webp";
import UniversalCompatability from "public/doc-processing-images/universal-compatibility.webp";
import VideoPoster from "public/doc-processing-images/video-poster.webp";
import { STEPS } from "../../../../constants/steps";
import centerdimg from "public/doc-processing-images/centered-task-list.webp";
import IntuitiveSvg from "public/doc-processing-images/Intuitive-img.svg";
import PoweredAiSvg from "public/doc-processing-images/poweredai.svg";
import ProfessionalSvg from "public/doc-processing-images/Professionalquality.svg";
import translateDocumentsBanner from "public/images/translate-documents-banner.webp";
import selectDocumentBanner from "public/images/select-document-banner.webp";
import downloadDocumentBanner from "public/images/download-document-banner.webp";
import centeredTaskSvg from "public/doc-processing-images/centered-task-list2.svg";
import VideoSection from "sections/Videosection";

import {
  GetStarttedForFreeSvg,
  GetStarttedIcon,
  HowItWorkSvg,
  MoodLogoDark,
  PlayVideoSvg,
  PricingSvg,
  SecurityFirstSvg,
  TickLogoLandingSvg,
} from "../../Icons";
import Footer from "../../Footer";
import {
  handleGetStartedClick,
  handleNavigateStartedClick,
} from "lib/getStartedUserClick";
import { Illustration } from "modules/FeaturesModule/Component/Illustration";
import QuestionsLegal from "modules/FeaturesModule/Component/QuestionsLegal";
import { useSelector } from "react-redux";
import Package from "@/templates/PricingPage/Main/Package";
import Hero from "sections/Hero";
import ImageWithText from "sections/ImageWithText";
import Steps from "sections/Steps";
import GridBlocks from "sections/GridBlocks";
import FaqSection from "sections/faq";
import FooterBanner from "sections/FooterBanner";
import { GoPlus } from "react-icons/go";
import { CiLaptop } from "react-icons/ci";
import ChooseSvg from "../../../../public/doc-processing-images/choose-img.svg";
import ChooselanguageSvg from "../../../../public/doc-processing-images/choose-language-img.svg";
import DownloadSvg from "../../../../public/doc-processing-images/download-img.svg";
// import Selectplan from "sections/Selectplan";
// import Videosection from "sections/Video";
import { Stepicon } from "@/components/SVGs";
import AdditionalPackage from "@/templates/PricingPage/Main/AdditionalPackage";

const Banner = () => {
  const router = useRouter();
  const { t } = useTranslation("translation");
  const { t: lt } = useTranslation("auth");
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null); // Specify the ref type

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setVideoPlaying(true);
    }
  };
  const handleStartClick = () => {
    handleGetStartedClick(router);
  };
  const handleStartNavigateClick = () => {
    handleNavigateStartedClick(router);
  };
  const { docquestionAnswers } = FeatureData();
  const packages = useSelector((state: any) => state.planStore.getPlan);

  const getPlans = packages?.data;

  const [selected, setSelected] = useState("monthly");
  const [currentPlan, setCurrentPlan] = useState(null);
  const [plan, setPlan] = useState<string>("monthly_billing");
  const video = "https://vimeo.com/1029371709?share=copy#t=0";
  const handleSelectPlan = (type: any) => {
    setSelected(type);
  };
  const HOW_IT_WORKS: { image: any; title: string; description: string }[] = [
    {
      image: ChooseSvg,
      title: t("choose_your_document"),
      description: t("supports_pdf_docx_txt_tab_csv_html"),
    },
    {
      image: ChooselanguageSvg,
      title: t("choose_the_target_language"),
      description: t("up_to_140_languages"),
    },
    {
      image: DownloadSvg,
      title: t("download_in_original_format"),
      description: t("fast_translation"),
    },
  ];

  const FAQS: { question: string; answer: string }[] = [
    {
      question: t("how_does_loki_ensure_accurate_translations?"),
      answer: t("loki_uses_advanced_AI_technology_to"),
    },
    {
      question: t("does_it_maintain_original_formatting"),
      answer: t("yes_loki_preserves_the_original_layout"),
    },
    {
      question: t("what_file_types_can_I_translate_with_loki?"),
      answer: t("you_can_translate_txt_.tab_.csv_.html"),
    },
    {
      question: t("are_my_documents_secure_during_the_translation_process?"),
      answer: t(
        "absolutely_loki_uses_advanced_encryption_and_does_not_store_any_files_on_its"
      ),
    },
    {
      question: t("how_long_does_it_take_to_complete_a_translation?"),
      answer: t("translations_are_completed_in_seconds"),
    },
    {
      question: t("can_I_translate_files"),
      answer: t("currently_loki_allows_you_to_select_one_target"),
    },
  ];
  const scrollContainerRef = useRef(null);

  const featuresPrice = [
    {
      id: "0",
      title: "Unlimited file uploads",
      free: true,
      pro: true,
      enterprise: true,
    },
    {
      id: "1",
      title: "Access to 140 advanced languages",
      free: true,
      pro: true,
      enterprise: true,
    },
    {
      id: "2",
      title: "Advanced categorixation and tagging",
      free: true,
      pro: true,
      enterprise: true,
    },
    {
      id: "3",
      title: "Advanced encryption",
      free: false,
      pro: true,
      enterprise: true,
    },
    {
      id: "4",
      title: "Professional level translation maintaining format",
      free: false,
      pro: true,
      enterprise: true,
    },
    // {
    //   id: "5",
    //   title: t("multi_Platform"),
    //   basic: `50 ${t("pages")}`,
    //   premium: `100 ${t("pages")}`,
    //   prO: `200 ${t("pages")}`,
    //   free: false,
    //   pro: true,
    //   enterprise: false,
    // },
    // {
    //   id: "6",
    //   title: t("audio_50word"),
    //   basic: `60 ${t("minutes")}`,
    //   premium: `120 ${t("minutes")}`,
    //   prO: `180 ${t("minutes")}`,
    //   free: false,
    //   pro: true,
    //   enterprise: false,
    // },
    // {
    //   id: "7",
    //   title: t("audio_100word"),
    //   basic: `10 ${t("resumes")}`,
    //   premium: `20 ${t("resumes")}`,
    //   prO: `30 ${t("resumes")}`,
    //   free: false,
    //   pro: false,
    //   enterprise: true,
    // },
    // {
    //   id: "8",
    //   title: t("Goal management and progress tracking"),
    //   free: true,
    //   pro: true,
    //   enterprise: true,
    // },
    // {
    //   id: "9",
    //   title: t("resume_review"),
    //   free: true,
    //   pro: true,
    //   enterprise: true,
    // },
    // {
    //   id: "10",
    //   title: t("image_200limit"),
    //   free: true,
    //   pro: true,
    //   enterprise: true,
    // },
    // {
    //     id: "11",
    //     title: t('premium_support'),
    //     free: false,
    //     pro: true,
    //     enterprise: true,
    // },
  ];

  // return (
  //   <section className="Banner" id="Banner">
  //     <div className="container llg:py-[30px]  py-8">
  //       {/* <div className="absolute top-1/5 right-1/4 transform -translate-y-1/4 ">
  //         <span
  //           className="text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
  //           onClick={handleStartClick}
  //         >
  //           <Image
  //             // @ts-ignore
  //             src={getStarttedButton}
  //             width={100}
  //             height={100}
  //             placeholder="blur"
  //             className="w-full h-full rounded-2xl object-contain"
  //             alt="banner"
  //           />
  //         </span>
  //       </div>
  //       <div className="absolute top-1/5 left-1/4 transform -translate-y-1/4 mt-5">
  //         <Logo className="max-w-[8.875rem] mx-auto grow" />
  //       </div> */}
  //       {/* <div className="absolute mb-10 top-5 mt-5 llg:right-12 llg:mr-12  lxl:right-30 lxl:mr-30  lmd:right-5 lmd:mr-5  sm:right-5 sm:mr-5">
  //         <span
  //           className="text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
  //           onClick={handleStartClick}
  //         >
  //           <GetStarttedIcon/>
  //           <Image
  //             // @ts-ignore
  //             src={getStarttedButton}
  //             width={100}
  //             height={100}
  //             placeholder="blur"
  //             // blurDataURL={item.imgUrl}
  //             className="w-full h-full rounded-2xl object-contain "
  //             alt="banner"
  //           />
  //         </span>
  //       </div> */}
  //       {/* <div className="absolute mb-10 top-5 mt-7 llg:left-13  llg:ml-13 lxl:left-30  lxl:ml-30 lmd:left-5  lmd:ml-5 sm:left-5  sm:ml-5">
  //         <Logo
  //           className="max-w-[8.875rem]  mx-auto grow"
  //           // dark={isLightMode}
  //         />
  //         <MoodLogoDark/>
  //       </div> */}

  //       <div className="flex flex-col justify-center items-center top-0">
  //         {/* <div className="lsm:max-w-[700px] llg:max-w-[850px] l2xl:max-w-[1060px] l3xl:max-w-[1120px] lxl:max-w-[970px] w-full flex justify-between items-center">
  //           <span>
  //             <MoodLogoDark />
  //           </span>
  //          <span
  //           className="text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
  //           onClick={handleStartClick}
  //         >
  //           <GetStarttedIcon/>

  //         </span>
  //         </div> */}
  //         <div className="w-full flex flex-col mt-10">
  //           <h1
  //             className="w-full mb-6 mt-[48px] text-center text-[60px] leading-[64px] tracking-[-1.24px] !font-extrabold !font-mont text-text-primary opacity-80
  // sm:text-[32px] sm:leading-[43px] sm:tracking-[-0.64px] sm:px-[2.5rem] pmd:text-[40px] pmd:leading-[64px] pmd:tracking-[-0.8px] pmd:px-[3rem] plg:text-[56px] plg:leading-[64px] plg:tracking-[-1.12px] 4xl:text-[64px] 4xl:leading-[64px] 4xl:tracking-[-1.28px] 4xl:px-[3rem]"
  //           >
  //             Translate Documents with Absolute Accuracy
  //           </h1>

  //           <p
  //             className="text-center !font-mont font-[600] text-[26px] leading-[1.2] mb-8 px-0
  //   sm:text-[18px] sm:leading-[1.4] pmd:text-[18px] pmd:leading-[32px] pmd:font-semibold pmd:px-[8rem] sm:px-[4rem]
  //   plg:text-[20px] plg:leading-[32px] plg:font-semibold
  //   4xl:text-[20px] 4xl:leading-[32px] 4xl:font-semibold plg:px-[12rem]"
  //             style={{
  //               color: "var(--Text-Secondary, rgba(248, 248, 248, 0.70))",
  //             }}
  //           >
  //             Into 140 languages, in just 3 clicks. Maintaining original
  //             formatting, even in scanned documents.
  //           </p>
  //         </div>

  //         <div className="flex items-center gap-7">
  //           <span
  //             className=" cursor-pointer  mb-[12px]"
  //             onClick={handleStartClick}
  //           >
  //             <button
  //               onClick={handleStartClick}
  //               className="subscribe-now-btn"
  //             >
  //               Get Started For Free
  //               {/* {t("coming_soon")} */}
  //             </button>

  //             {/* <Image
  //               // @ts-ignore
  //               src={getStarttedForFreeButton}
  //               width={100}
  //               height={100}
  //               placeholder="blur"
  //               // blurDataURL={item.imgUrl}
  //               className="w-full h-full rounded-2xl object-contain "
  //               alt="banner"
  //             /> */}
  //           </span>
  //         </div>
  //         <p
  //           className="text-center font-mont font-semibold text-[10px] leading-[12px] mb-1"
  //           style={{
  //             color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
  //           }}
  //         >
  //           Try your first document for free
  //         </p>
  //       </div>
  //       <div className="flex flex-col justify-center items-center py-3">
  //         {/* <div
  //           className={`mt-auto relative flex  rounded-3xl
  //          min-h-[225px] min-w-[400px] max-w-[400px]
  //         lsm:min-h-[421.875px] lsm:min-w-[750px] lsm:max-w-[800px]
  //         llg:min-h-[506px] llg:min-w-[900px] llg:max-w-[1000px]
  //         lxl:min-h-[510px] lxl:min-w-[900px] lxl:max-w-[700px]
  //         l2xl:min-h-[510px] l2xl:min-w-[900px] l2xl:max-w-[700px]
  //         l3xl:min-h-[630px] l3xl:min-w-[1120px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               AppScreens.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div> */}
  //         <Illustration
  //           img={AppScreens}
  //           className="flex justify-center"
  //           height={420}
  //           width={1200}
  //         />

  //         <div className="flex flex-col items-center self-stretch mb-[160px]  plg:mb-[128px] pmd:mb-[96px] sm:mb-[64px] sm:text-center sm:px-[3rem]">
  //           <p
  //             className=" font-mont font-normal text-[15px] leading-[1.2] mb-[24px] mt-[5rem]"
  //             style={{
  //               color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
  //               lineHeight: "18px",
  //             }}
  //           >
  //             Trusted by over{" "}
  //             <span className="opacity-80 text-[rgba(248,248,248,0.95)] font-mont text-[14px] font-medium text-center ">
  //               50,000
  //             </span>{" "}
  //             professionals from different sectors.
  //           </p>
  //           {/* <p ">
  //           Trusted by over 50,000 professionals from different sectors.
  //         </p> */}

  //           <Image
  //             // @ts-ignore
  //             src={AvatarGroup}
  //             width={150}
  //             // placeholder="blur"
  //             // blurDataURL={item.imgUrl}
  //             className="w-full h-full rounded-2xl object-contain"
  //             alt="banner"
  //           />
  //         </div>
  //       </div>
  //       <div className="flex flex-col justify-center items-center ">
  //         <span
  //           className="text-[rgba(248,248,248,0.70)]  font-mont mb-3"
  //         // onClick={handleStartClick}
  //         >
  //           <HowItWorkSvg />
  //           {/* <Image
  //             // @ts-ignore
  //             src={howItWorkButton}
  //             width={100}
  //             height={100}
  //             placeholder="blur"
  //             // blurDataURL={item.imgUrl}
  //             className="w-full h-full rounded-2xl object-contain "
  //             alt="banner"
  //           /> */}
  //         </span>

  //         <div className="">
  //           <h2
  //             className="bg-landing-page-headline bg-clip-text text-transparent mb-[64px] text-center text-3xl  text-[48px] font-extrabold leading-[56px] opacity-90 font-mont w-[666px] px-[3rem] 4xl:px-[1rem] pmd:px-[5rem] pmd:text-[32px] sm:w-[400px] sm:px-[1rem] sm:text-[28px] sm:leading-[40px]"
  //             style={{
  //               color: "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
  //               letterSpacing: "-1.24px",
  //               // lineHeight: "75px",
  //             }}
  //           >
  //             Perfect Translation in Three Simple Steps
  //           </h2>
  //         </div>
  //       </div>
  //       <div className="flex flex-col justify-center items-center py-3  mb-15">
  //         {/* <div
  //           className={`mt-auto relative flex  rounded-3xl
  //          min-h-[200px] min-w-[380px] max-w-[400px]
  //         lsm:min-h-[315.875px] lsm:min-w-[700px] lsm:max-w-[800px]
  //         llg:min-h-[386px] llg:min-w-[850px] llg:max-w-[1000px]
  //         lxl:min-h-[386px] lxl:min-w-[850px] lxl:max-w-[900px]
  //         l2xl:min-h-[486px] l2xl:min-w-[850px] l2xl:max-w-[900px]
  //         l3xl:min-h-[480px] l3xl:min-w-[1010px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               howItWorkCards.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div> */}
  //         <Illustration
  //           img={howItWorkCards}
  //           className="flex justify-center"
  //           height={350}
  //           width={1200}
  //         />
  //       </div>
  //       {/* Finally, a Simple Translator that Keeps the Format! */}
  //       <div className="flex flex-col justify-center items-center">
  //         <TickLogoLandingSvg />
  //         {/* <Image
  //           // @ts-ignore
  //           src={tickLogo}
  //           width={70}
  //           height={70}
  //           placeholder="blur"
  //           // blurDataURL={item.imgUrl}
  //           className="w-full h-full rounded-2xl object-contain"
  //           alt="banner"
  //         /> */}
  //         <div>
  //           <h2
  //             className="bg-landing-page-headline bg-clip-text text-transparent my-[112px] text-center text-3xl  text-[48px] font-extrabold leading-[56px] opacity-90 font-mont w-[960px] px-[1rem] 4xl:px-[1rem] plg:w-[768px] pmd:text-[32px] pmd:px-[5rem] sm:w-[327px] sm:text-[28px] sm:leading-[40px] sm:px-[1rem] pmd:my-[80px]"
  //             style={{
  //               color: "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
  //               letterSpacing: "-1.24px",
  //               // lineHeight: "75px",
  //             }}
  //           >
  //             Finally, a Simple Translator that Keeps the Format!
  //           </h2>
  //         </div>
  //       </div>
  //       {/* mt-20 font-mont !rounded-[108px] !border !border-[5px] !border-n-5 py-15 */}
  //       <div className="flex flex-col justify-center items-center py-3">
  //         {/* <div
  //           className={`mt-auto relative flex   rounded-3xl
  //          min-h-[200px] min-w-[470px] max-w-[400px]
  //         lsm:min-h-[350px] lsm:min-w-[850px] lsm:max-w-[800px]
  //         llg:min-h-[430px] llg:min-w-[1050px] llg:max-w-[1000px]
  //         lxl:min-h-[430px] lxl:min-w-[1000px] lxl:max-w-[700px]
  //         l2xl:min-h-[510px] l2xl:min-w-[1050px] l2xl:max-w-[700px]
  //         l3xl:min-h-[500px] l3xl:min-w-[1160px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               cardsCleanDesign.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div> */}
  //         <Illustration
  //           img={LargeScreen1}
  //           className="flex justify-center"
  //           height={350}
  //           width={1200}
  //         />
  //       </div>

  //       <div className="flex flex-col justify-center items-center py-3">
  //         {/* <div
  //           className={`mt-auto relative flex  rounded-3xl
  //          min-h-[200px] min-w-[400px] max-w-[400px]
  //         lsm:min-h-[350px] lsm:min-w-[680px] lsm:max-w-[800px]
  //         llg:min-h-[430px] llg:min-w-[850px] llg:max-w-[1000px]
  //         lxl:min-h-[430px] lxl:min-w-[800px] lxl:max-w-[700px]
  //         l2xl:min-h-[495px] l2xl:min-w-[850px] l2xl:max-w-[700px]
  //         l3xl:min-h-[500px] l3xl:min-w-[1000px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               cardspoweredByAi.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div> */}

  //         <Illustration
  //           img={LargeScreen2}
  //           className="flex justify-center"
  //           height={350}
  //           width={1200}
  //         />
  //       </div>
  //       <div className="flex flex-col justify-center items-center py-3">
  //         {/* <div
  //           className={`mt-auto relative flex  rounded-3xl
  //             min-h-[200px] min-w-[400px] max-w-[400px]
  //            lsm:min-h-[350px] lsm:min-w-[680px] lsm:max-w-[800px]
  //            llg:min-h-[430px] llg:min-w-[850px] llg:max-w-[1000px]
  //            lxl:min-h-[430px] lxl:min-w-[800px] lxl:max-w-[700px]
  //            l2xl:min-h-[495px] l2xl:min-w-[850px] l2xl:max-w-[700px]
  //            l3xl:min-h-[500px] l3xl:min-w-[1000px] l3xl:max-w-[1000px]
  //            `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               cardsprofessional.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div> */}

  //         <Illustration
  //           img={LargeScreen3}
  //           className="flex justify-center"
  //           height={350}
  //           width={1200}
  //         />
  //       </div>
  //       {/* <div className="flex flex-col items-center justify-between">
  //         <div
  //           className="mt-4 font-mont !rounded-[108px] !border  !border-n-5  max-w-[1000px] "
  //           style={{
  //             borderRadius: "57px",
  //             background:
  //               "linear-gradient(124deg, rgba(248, 248, 248, 0.03) 0%, rgba(248, 248, 248, 0.00) 46.5%), linear-gradient(180deg, rgba(248, 248, 248, 0.02) 0%, rgba(248, 248, 248, 0.00) 100%)",
  //           }}
  //         >
  //           <div className="max-w-[800px] container flex llg:flex-row flex-col items-center justify-between lmd:gap-16 gap-8 pb-10">
  //             <Illustration
  //               img={Frame}
  //               className="flex justify-center max-w-[1200px] lmd:h-[350px] rounded-3xl overflow-hidden"
  //               height={350}
  //               width={1200}
  //             />
  //             <div className="flex flex-col lmd:items-start items-center max-w-[270px] m-15 pt-10">
  //               <Image
  //                 // @ts-ignore
  //                 src={CircleIconButton}
  //                 width={50}
  //                 height={50}
  //                 placeholder="blur"
  //                 // blurDataURL={item.imgUrl}
  //                 className="w-full h-full rounded-2xl object-contain "
  //                 alt="banner"
  //               />
  //               <div className="max-w-[300px] mt-10">
  //                 <h2
  //                   className="text-center font-mont font-normal mb-6"
  //                   style={{
  //                     fontSize: "30px",
  //                     lineHeight: "44px",
  //                     letterSpacing: "-0.96px",
  //                     background:
  //                       "var(--Landing-page-Headline, linear-gradient(93deg, rgba(248, 248, 248, 0.90) 0.1%, rgba(248, 248, 248, 0.50) 100.1%))",
  //                     backgroundClip: "text",
  //                     WebkitBackgroundClip: "text",
  //                     WebkitTextFillColor: "transparent",
  //                     fontWeight: "600",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {t("Powered_by")}
  //                 </h2>
  //                 <p
  //                   className="text-center font-mont font-normal text-[15px] leading-[1.2] mb-6"
  //                   style={{
  //                     color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
  //                     lineHeight: "18px",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {t("accurate_and_fast")}
  //                 </p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div> */}
  //       {/* <EmpowerProductivity /> */}
  //       {/* <div className="flex flex-col items-center justify-between">
  //         <div
  //           className="mt-4 font-mont !rounded-[108px] !border  !border-n-5 py-3 max-w-[1000px]"
  //           style={{
  //             borderRadius: "57px",
  //             background:
  //               "linear-gradient(124deg, rgba(248, 248, 248, 0.03) 0%, rgba(248, 248, 248, 0.00) 46.5%), linear-gradient(180deg, rgba(248, 248, 248, 0.02) 0%, rgba(248, 248, 248, 0.00) 100%)",
  //           }}
  //         >
  //           <div className="container flex llg:flex-row flex-col items-center justify-between lmd:gap-16 gap-8 pb-10 max-w-[800px]">
  //             <div className="flex flex-col lmd:items-start items-center max-w-[270px] m-15 pt-10">
  //               <Image
  //                 // @ts-ignore
  //                 src={CircleIconButton2}
  //                 width={50}
  //                 height={50}
  //                 placeholder="blur"
  //                 // blurDataURL={item.imgUrl}
  //                 className="w-full h-full rounded-2xl object-contain "
  //                 alt="banner"
  //               />
  //               <div className="max-w-[300px] mt-10">
  //                 <h2
  //                   className="text-center font-mont font-normal mb-6"
  //                   style={{
  //                     fontSize: "30px",
  //                     lineHeight: "44px",
  //                     letterSpacing: "-0.96px",
  //                     background:
  //                       "var(--Landing-page-Headline, linear-gradient(93deg, rgba(248, 248, 248, 0.90) 0.1%, rgba(248, 248, 248, 0.50) 100.1%))",
  //                     backgroundClip: "text",
  //                     WebkitBackgroundClip: "text",
  //                     WebkitTextFillColor: "transparent",
  //                     fontWeight: "600",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {t("quality_in_Seconds")}
  //                 </h2>
  //                 <p
  //                   className="text-center font-mont font-normal text-[15px] leading-[1.2] mb-6"
  //                   style={{
  //                     color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
  //                     lineHeight: "18px",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   {t("high_standards")}
  //                 </p>
  //               </div>

  //             </div>

  //             <Illustration
  //               img={Frame2}
  //               className="flex justify-center max-w-[1200px] lmd:h-[350px] rounded-3xl overflow-hidden"
  //               height={350}
  //               width={1200}
  //             />
  //           </div>
  //         </div>
  //       </div> */}

  //       {/* <div className="flex flex-col justify-center items-center py-3">
  //         <div
  //           className={`mt-auto relative flex  rounded-3xl
  //         min-h-[130px] min-w-[370px] max-w-[400px]
  //         lsm:min-h-[250px] lsm:min-w-[650px] lsm:max-w-[800px]
  //         llg:min-h-[270px] llg:min-w-[750px] llg:max-w-[1000px]
  //         lxl:min-h-[270px] lxl:min-w-[750px] lxl:max-w-[700px]
  //         l2xl:min-h-[270px] l2xl:min-w-[750px] l2xl:max-w-[700px]
  //         l3xl:min-h-[340px] l3xl:min-w-[1000px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               UsersListFrame.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div>
  //       </div> */}
  //       <Illustration
  //         img={UsersListFrame}
  //         className="flex justify-center my-[113px] 4xl:my-[80px] sm:my-[40px] "
  //         height={364}
  //         width={1232}
  //       />

  //       {/* video */}
  //       <div className="flex flex-col justify-center items-center gap-7 mt-7">
  //         {/* <div className="flex items-center gap-7 mt-5"> */}
  //         {/* <div className="flex items-center gap-1"> */}
  //         <PlayVideoSvg />
  //         {/* <span
  //               className="text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
  //               onClick={handleStartClick}
  //             >
  //               <Image
  //                 // @ts-ignore
  //                 src={playVideoButton}
  //                 width={100}
  //                 height={100}
  //                 placeholder="blur"
  //                 // blurDataURL={item.imgUrl}
  //                 className="w-full h-full rounded-2xl object-contain "
  //                 alt="banner"
  //               />
  //             </span> */}
  //         {/* <span
  //               className="mb-3 text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
  //               onClick={handleStartClick}
  //               style={{
  //                 display: "flex",
  //                 padding: "8px 16px",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 gap: "10px",
  //                 borderRadius: "32px",
  //                 border:
  //                   "1.5px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40))",
  //                 background:
  //                   "var(--Surface-Background, rgba(40, 40, 40, 0.70))",
  //                 // boxShadow: "2px 4px 16px 0px rgba(248, 248, 248, 0.06)",
  //                 backdropFilter: "blur(50px)",
  //               }}
  //             >
  //               PLAY VIDEO
  //             </span> */}
  //         {/* <Button
  //               size="lg"
  //               onClick={handleStartClick}
  //               className="mb-3 cursor-pointer font-mont !rounded-[32px] !border !border-[1.5px] !border-[rgba(255,255,255,0.40)] !shadow-[2px_4px_16px_0px_rgba(248,248,248,0.06)] !backdrop-blur-[50px] !bg-[rgba(40,40,40,0.70)] !px-6 !py-1 flex justify-center items-center gap-[10px] text-[rgba(248,248,248,0.70)]"
  //               style={{
  //                 borderColor: "rgba(255, 255, 255, 0.4)",
  //               }}
  //             >
  //               {t("play_video_cap")}
  //             </Button> */}
  //         {/* </div> */}
  //         {/* </div> */}
  //         <div>
  //           <h2
  //             className=" bg-landing-page-headline bg-clip-text text-transparent my-[16px] text-center text-[50px] font-extrabold leading-[56px] opacity-90 font-mont w-[719px] px-[1rem] 4xl:px-[1rem] 4xl:w-[792px] 4xl:text-[48px] plg:w-[680px] pmd:text-[32px] pmd:px-[6.5rem] sm:w-[350px] sm:text-[28px] sm:leading-[40px] sm:px-[1rem] pmd:my-[16px]"
  //             style={{
  //               color: "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
  //               letterSpacing: "-1.24px",
  //               // lineHeight: "75px",
  //             }}
  //           >
  //             See how easy it is to translate a document with Loki
  //           </h2>

  //           {/* <h2
  //             className="text-center font-mont font-normal mb-6"
  //             style={{
  //               fontSize: "30px",
  //               lineHeight: "44px",
  //               letterSpacing: "-0.96px",
  //               background:
  //                 "var(--Landing-page-Headline, linear-gradient(93deg, rgba(248, 248, 248, 0.90) 0.1%, rgba(248, 248, 248, 0.50) 100.1%))",
  //               backgroundClip: "text",
  //               WebkitBackgroundClip: "text",
  //               WebkitTextFillColor: "transparent",
  //               fontWeight: "600",
  //             }}
  //           >
  //             See how easy it is to translate a document with Mood
  //           </h2> */}
  //         </div>
  //       </div>
  //       <div className="flex flex-col justify-center items-center py-3">
  //         <Illustration
  //           img={videoFrame2}
  //           className="flex justify-center my-[113px] 4xl:my-[80px]  sm:my-[40px] "
  //           height={631}
  //           width={1076}
  //         />
  //         {/* <VideoPlayer
  //         imgSrc={
  //           "https://storage.googleapis.com/lokichat-profile/Chat%20with%20docs%20middle%20video.JPG"
  //         }
  //         videoSrc={
  //           "https://player.vimeo.com/video/963491488?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
  //         }
  //       /> */}
  //       </div>

  //       {/* Pricing */}
  //       {/* video */}
  //       <div className="flex flex-col justify-center items-center mt-5 ">
  //         <div className="flex items-center gap-7">
  //           <PricingSvg />
  //           {/* <Image
  //               // @ts-ignore
  //               src={pricingButton}
  //               width={100}
  //               height={100}
  //               placeholder="blur"
  //               // blurDataURL={item.imgUrl}
  //               className="w-full h-full rounded-2xl object-contain "
  //               alt="banner"
  //             /> */}
  //         </div>

  //         <div>
  //           <h2
  //             className="bg-landing-page-headline bg-clip-text text-transparent my-[16px] text-center text-[48px] font-extrabold leading-[56px] opacity-90 font-mont w-[960px] px-[1rem] 4xl:px-[1rem] plg:w-[722px] pmd:text-[36px] pmd:px-[0] sm:w-[343px] sm:text-[28px] sm:leading-[40px] sm:px-[2rem] plg:my-[16px] pmd:w-[508px]"
  //             style={{
  //               color: "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
  //               letterSpacing: "-1.24px",
  //               // lineHeight: "75px",
  //             }}
  //           >
  //             Choose your simplicity level
  //           </h2>
  //         </div>
  //         {/* <p
  //           className=" font-mont font-normal text-[12px] leading-[1.2] mb-6"
  //           style={{
  //             color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
  //             lineHeight: "18px",
  //           }}
  //         >
  //           Flexibility and value. Find the option that best suits your needs.
  //         </p> */}

  //         <p className="font-mont font-semibold text-[20px] leading-[32px] text-center text-[rgba(248,248,248,0.50)] opacity-80 w-[719px] mb-[40px] 4xl:text-[20px] sm:w-[372px] sm:px-[2rem] pmd:px-[7rem]">
  //           Flexibility and value. Find the option that best suits your needs.
  //         </p>

  //         {/* <ToggleButton /> */}

  //         {/* <div>
  //           <h4 className="bg-landing-page-headline bg-clip-text text-transparent font-mont text-[36px] mb-[22px] font-bold leading-[56px] tracking-[-0.72px] text-center plg:hidden">
  //             Select plan
  //           </h4>
  //           <div className="bg-[rgba(18,18,18,0.40)] w-80 h-12 rounded-full flex items-center p-1 relative mb-[48px]">
  //             <div
  //               className={`absolute top-0 left-0 h-full w-1/2 bborder-[2.286px] border-white/40 bg-[rgba(248,248,248,0.05)] shadow-[0px_6.095px_6.095px_rgba(0,0,0,0.25)] rounded-full transition-transform duration-300 ease-in-out ${
  //                 selected === "payasyougo"
  //                   ? "translate-x-full"
  //                   : "translate-x-0"
  //               }`}
  //             />
  //             <button
  //               onClick={() => handleSelectPlan("monthly")}
  //               className={`w-1/2 z-10 text-[rgba(248,248,248,0.95)] focus:outline-none transition-colors duration-300  font-mont font-bold  ${
  //                 selected === "monthly"
  //                   ? "text-[rgba(248,248,248,0.95)]"
  //                   : "text-gray-400"
  //               }`}
  //             >
  //               Monthly Billing
  //             </button>

  //             <button
  //               onClick={() => handleSelectPlan("payasyougo")}
  //               className={`w-1/2 z-10 text-[rgba(248,248,248,0.95)] focus:outline-none transition-colors duration-300  font-mont font-bold  ${
  //                 selected === "payasyougo"
  //                   ? "text-[rgba(248,248,248,0.95)]"
  //                   : "text-gray-400"
  //               }`}
  //             >
  //               Pay As You Go
  //             </button>
  //           </div>
  //         </div> */}

  //         <div>
  //           <button className="flex justify-center items-center gap-2 px-4 py-2 rounded-[24px]  border-[1.5px] border-[rgba(248,248,248,0.05)] text-center text-[14px] font-bold leading-[20px] tracking-[0.7px] uppercase opacity-80 text-[rgba(248,248,248,0.50)] mb-[34px] shadow-[inset_2px_4px_16px_0_rgba(248,248,248,0.06)]">
  //             CHOOSE A PLAN
  //           </button>
  //         </div>
  //       </div>

  //       <div
  //         onClick={handleStartClick}
  //         className="w-full flex grow items-center justify-center mt-[20px] mb-[40px] plg:overflow-auto plg:scroll-smooth plg:scrollbar-none plg:py-6 plg:before:shrink-0 plg:before:w-8 plg:after:shrink-0 plg:after:w-8 pmd:justify-start pmd:items-start"
  //       >

  //         {getPlans &&
  //           getPlans[plan]?.map((x: any) => (
  //             <Package
  //               item={x}
  //               package_plan={getPlans[plan]}
  //               currentPlan={currentPlan}
  //               plan={plan}
  //               key={x.id}
  //               featuresPrice={featuresPrice}
  //             />
  //           ))}

  //         {/* <div
  //           className={`mt-auto relative flex  rounded-3xl
  //          min-h-[200px] min-w-[380px] max-w-[400px]
  //         lsm:min-h-[350px] lsm:min-w-[700px] lsm:max-w-[800px]
  //         llg:min-h-[406px] llg:min-w-[850px] llg:max-w-[1000px]
  //         lxl:min-h-[404px] lxl:min-w-[900px] lxl:max-w-[900px]
  //         l2xl:min-h-[486px] l2xl:min-w-[850px] l2xl:max-w-[900px]
  //         l3xl:min-h-[504px] l3xl:min-w-[1000px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               PricingContent.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //           }}
  //         ></div> */}
  //         {/* <Illustration
  //           img={PricingContent}
  //           className="flex justify-center max-w-[1200px] lmd:h-[420px] rounded-3xl overflow-hidden"
  //           height={420}
  //           width={1200}
  //         /> */}
  //       </div>
  //       {/* Frequently asked questions */}

  //           <div className="container mt-20  pt-5">
  //             <div className="flex  gap-[5rem] lg:flex-col ">
  //               <div className="w-3/6 lg:w-full">
  //                 <div
  //                   className="flex flex-col ss:items-center lmd:items-center
  //              items-center llg:items-start
  //              llg:max-w-[423px] llg:mb-5 llg:mt-0 md:w-full pmd:justify-center"
  //                 >
  //                   <h2
  //                     className=" bg-landing-page-headline bg-clip-text text-transparent my-[16px] text-[48px] font-extrabold leading-[56px] opacity-90 font-mont w-[300px]  4xl:text-[48px]  sm:text-[28px] sm:leading-[40px] sm:px-[1rem] pmd:my-[16px] pmd:w-full pmd:px-0 pmd:text-[36px] pmd:text-center"
  //                     style={{
  //                       color:
  //                         "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
  //                       letterSpacing: "-1.24px",
  //                       // lineHeight: "75px",
  //                     }}
  //                   >
  //                     Frequently asked questions
  //                   </h2>

  //                   <p className="font-mont text-xl font-semibold leading-8 mb-6 text-[rgba(248,248,248,0.50)] w-[360px] pmd:w-full pmd:text-center sm:px-[2rem] plg:pr-[2rem]">
  //                     Contact us via support if you have any more questions.
  //                   </p>

  //                   <button
  //                     onClick={handleStartClick}
  //                     className=" h-[52px] flex justify-center items-center font-semibold gap-2 cursor-pointer plg:text-sm px-[32px] rounded-[50px] border-[1.5px] border-[#2A85FF] bg-[#2A85FF] shadow-[2px_4px_16px_rgba(248,248,248,0.06)] backdrop-blur-[50px]"
  //                   >
  //                     Contact Us
  //                     {/* {t("coming_soon")} */}
  //                   </button>

  //                   {/* <button
  //                 className="bg-[#282828B2] hover:bg-[#3a3a3a] text-[#F8F8F8B2] rounded-full px-6 py-2 font-mont font-bold border border-[#4f4949b2] w-auto md:w-[40%] mb-5 mt-5 llg:mt-0 llg:mb-auto"
  //                 style={{
  //                   fontSize: "12px", // Small size text for small screens
  //                   padding: "10px 20px", // Padding remains consistent
  //                   border: "2px solid rgb(52 48 48 / 70%)",
  //                 }}
  //               >
  //                 Contact Us
  //               </button> */}

  //                   {/* <span
  //                 className="text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
  //                 onClick={handleStartClick}
  //               >
  //                 <Image
  //                   // @ts-ignore
  //                   src={contactUsButton}
  //                   width={100}
  //                   height={100}
  //                   placeholder="blur"
  //                   // blurDataURL={item.imgUrl}
  //                   className="w-full h-full rounded-2xl object-contain "
  //                   alt="banner"
  //                 />
  //               </span> */}
  //                 </div>
  //               </div>

  //               <div className="w-3/6 lg:w-full">
  //               <div className="flex flex-col lmd:items-start items-center llg:mt-1 lmd:mt-5 lmd:max-w-[800px] ">
  //               {/* <div className=" mx-auto my-8"> */}
  //               {docquestionAnswers.map((item, index) => (
  //                 <QuestionsLegal
  //                   key={index}
  //                   question={item.question}
  //                   answer={item.answer}
  //                 />
  //               ))}
  //               {/* </div> */}
  //             </div>
  //               </div>

  //             </div>

  //       </div>

  //       {/* last banner */}
  //       <div className="flex flex-col justify-center items-center py-3">
  //         {/* <div
  //           className={`mt-auto relative flex  rounded-3xl h-[580px] plg:h-[600px] pmd:h-[480px] sm:h-[230px]
  //          min-h-[225px] min-w-[365px] max-w-[400px]
  //         lsm:min-h-[421.875px] lsm:min-w-[720px] lsm:max-w-[800px]
  //         llg:min-h-[506px] llg:min-w-[900px] llg:max-w-[1000px]
  //         lxl:min-h-[510px] lxl:min-w-[900px] lxl:max-w-[700px]
  //         l2xl:min-h-[510px] l2xl:min-w-[900px] l2xl:max-w-[700px]
  //         l3xl:min-h-[630px] l3xl:min-w-[1000px] l3xl:max-w-[1000px]
  //         `}
  //           style={{
  //             // borderRadius: "10px",
  //             background: `var(--Background-bg-10, url(${
  //               CTAcontent.src
  //             }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
  //              objectFit:"cover",
  //           }}
  //         ></div> */}
  //         <Illustration
  //           img={LargeScreenBanner}
  //           className="flex justify-center rounded-1xl"
  //           height={814}
  //           width={1280}
  //         />
  //       </div>
  //       {/* footer */}
  //     </div>
  //     {/* <Footer/> */}
  //   </section>
  // );

  return (
    <>
      {/* <Hero
      title="Translate Documents with Absolute Accuracy"
      subtitle="Into 140 languages, in just 3 clicks. Maintaining original formatting, even in scanned documents."
      buttonLabel="Get started for free"
      image={VideoPoster}
    /> */}
      <div className="flex flex-col justify-center items-center top-0">
        {/* <div className="lsm:max-w-[700px] llg:max-w-[850px] l2xl:max-w-[1060px] l3xl:max-w-[1120px] lxl:max-w-[970px] w-full flex justify-between items-center">
            <span>
              <MoodLogoDark />
            </span>
           <span
            className="text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
            onClick={handleStartClick}
          >
            <GetStarttedIcon/>

          </span>
          </div> */}
        <div className="w-full flex flex-col mt-10">
          <h1
            className="w-full mb-4 mt-[48px] text-center text-[62px] leading-[64px] tracking-[-1.24px] !font-extrabold !font-mont text-text-primary opacity-80
  sm:text-[32px] sm:leading-[43px] sm:tracking-[-0.64px] sm:px-[2.5rem] pmd:text-[40px] pmd:leading-[64px] pmd:tracking-[-0.8px] pmd:px-[3rem] plg:text-[56px] plg:leading-[64px] plg:tracking-[-1.12px] 4xl:text-[64px] 4xl:leading-[64px] 4xl:tracking-[-1.28px] 4xl:px-[3rem] max-w-7xl m-auto"
          >
            {t("translate_documents_with_absolute_accuracy")}
          </h1>

          <p
            className="text-center !font-mont font-[500] text-[20px] leading-[1.2] mb-10 px-0 max-w-6xl m-auto 
    sm:text-[18px] sm:leading-[1.4] pmd:text-[18px] pmd:leading-[32px] pmd:font-semibold pmd:px-[8rem] sm:px-[4rem]
    plg:text-[20px] plg:leading-[32px] plg:font-semibold
    4xl:text-[20px] 4xl:leading-[32px] 4xl:font-semibold plg:px-[12rem]"
            style={{
              color: "var(--Text-Secondary, rgba(248, 248, 248, 0.70))",
            }}
          >
            {t("into_140_languages.")}
          </p>
        </div>

        <div className="flex items-center gap-7">
          <span
            className=" cursor-pointer  mb-[12px]"
            onClick={handleStartClick}
          >
            <button onClick={handleStartClick} className="subscribe-now-btn">
              {t("get_started_for_free")}
              {/* {t("coming_soon")} */}
            </button>

            {/* <Image
                // @ts-ignore
                src={getStarttedForFreeButton}
                width={100}
                height={100}
                placeholder="blur"
                // blurDataURL={item.imgUrl}
                className="w-full h-full rounded-2xl object-contain "
                alt="banner"
              /> */}
          </span>
        </div>
        <p
          className="text-center font-mont font-semibold text-[10px] leading-[12px] mb-1"
          style={{
            color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
          }}
        >
          {t("try_your_first_document_for_free")}
        </p>
      </div>

      <div className="flex flex-col justify-center items-center py-3">
        {/* <div
            className={`mt-auto relative flex  rounded-3xl
           min-h-[225px] min-w-[400px] max-w-[400px]
          lsm:min-h-[421.875px] lsm:min-w-[750px] lsm:max-w-[800px]  
          llg:min-h-[506px] llg:min-w-[900px] llg:max-w-[1000px] 
          lxl:min-h-[510px] lxl:min-w-[900px] lxl:max-w-[700px] 
          l2xl:min-h-[510px] l2xl:min-w-[900px] l2xl:max-w-[700px] 
          l3xl:min-h-[630px] l3xl:min-w-[1120px] l3xl:max-w-[1000px] 
          `}
            style={{
              // borderRadius: "10px",
              background: `var(--Background-bg-10, url(${
                AppScreens.src
              }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
            }}
          ></div> */}
        {/* <Illustration
            img={AppScreens}
            className="flex justify-center"
            height={420}
            width={1200}
          /> */}

        <div className="py-[32px] sm:py-[6px] my-12 px-10 sm:px-5">
          <div className="container">
            <div className="flex items-center">
              <div className="relative lg:hidden">
                <Image src={selectDocumentBanner} />
                <div className="absolute top-0 left-0 z-1 p-6 pt-8">
                  <p className="!font-mont font-[600] text-[18px] leading-[1.2] text-white whitespace-nowrap">
                    {t("select_document_content")}
                  </p>
                </div>
              </div>
              <div className="relative scale-[1.12] z-2">
                <Image
                  src={translateDocumentsBanner}
                  className="rounded-[40px]"
                />
                <div className="absolute top-0 left-0 z-1 p-8 pt-10 sm:p-7">
                  <p className="!font-mont font-[600] text-[18px] leading-[1.2] text-white whitespace-nowrap">
                    {t("translate_document")}
                  </p>
                  <p className="!font-mont font-[500] text-[14px] mt-2 sm:mt-1 leading-normal text-[#f8f8f8b3] max-w-80 ">
                    {t("translate_document_content")}
                  </p>
                </div>
              </div>
              <div className="relative lg:hidden">
                <div className="absolute top-0 left-0 z-1 p-6 pl-10">
                  <p className="!font-mont font-[600] text-[18px] leading-[1.2] text-white whitespace-nowrap">
                    {t("download_document_content")}
                  </p>
                </div>
                <Image src={downloadDocumentBanner} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center self-stretch  sm:text-center sm:px-[3rem]">
          <p
            className=" font-mont font-normal text-[15px] leading-[1.2] mb-[24px] mt-1"
            style={{
              color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
              lineHeight: "18px",
            }}
          >
            {t("trusted_by_over")}
            <span className="opacity-80 text-[rgba(248,248,248,0.95)] font-mont text-[14px] font-medium text-center ">
              {t("50000")}
            </span>{" "}
            {t("professionals_from_different_sectors.")}
          </p>
          {/* <p ">
            Trusted by over 50,000 professionals from different sectors.
          </p> */}

          <Image
            // @ts-ignore
            src={AvatarGroup}
            width={150}
            // placeholder="blur"
            // blurDataURL={item.imgUrl}
            className="w-full h-full rounded-2xl object-contain"
            alt="banner"
          />
        </div>
      </div>
      {/* <VideoSection>
        <div
          className="how-it-work-section"
          style={{ position: "relative", height: "100%" }}
        >
          <video
            width="100%"
            height="100%"
            controls={videoPlaying}
            poster="/images/video-poster-new.png"
            ref={videoRef}
          >
            <source src="/images/promo-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {!videoPlaying && (
            <div
              className="video-start-btn cursor-pointer"
              onClick={handleVideoPlay}
            >
              <div className="play-triangle"></div>
            </div>
          )}
        </div>
      </VideoSection> */}
      <GridBlocks
        title={t("perfect_translation")}
        badge={t("how_it_works")}
        blocks={HOW_IT_WORKS}
        showBlockNumber={true}
      />

      <div className="flex items-center justify-center mb-20 mt-15 lg:mb-12 lg:mt-10 md:mb-8 md:mt-6">
        <TickLogoLandingSvg />
      </div>
      <ImageWithText
        sectionHeading={t("finally_a_simple_translator")}
        icon={<GoPlus fontSize={24} color="#F8F8F8B2" />}
        title={t("interactive_document_analysis")}
        description={t("loki_AI_chat_allows_you_to_interactively")}
        image={IntuitiveSvg}
        direction="row"
      />
      <ImageWithText
        icon={<Stepicon />}
        title={t("supports_multiple_formats")}
        description={t("easily_upload_and_chat")}
        image={PoweredAiSvg}
        direction="row-reverse"
      />
      <ImageWithText
        icon={<CiLaptop fontSize={24} color="#F8F8F8B2" />}
        title={t("universal_compatibility_and_multilingual_support")}
        description={t("chat_with_any_type_of_document")}
        image={ProfessionalSvg}
        direction="row"
      />
      {/* <Steps
      title="How Loki’s AI Chat Works?"
      description="Step-by-Step Guide to Using Loki’s AI Document Chat"
      subtitle="HOW IT WORKS"
      steps={STEPS}
      image={VideoPoster}
    />
  */}
      <div className="flex flex-col justify-center items-center py-10">
        <Illustration
          img={FrameAiPowered}
          className="flex justify-center sm:h-[235px] sm:w-[235px]"
          height={375}
          width={375}
        />
        <div className="flex flex-col justify-center items-center mt-5">
          <div className="flex items-center gap-7">
            <span
              className="text-[rgba(248,248,248,0.70)]  font-mont mb-3"
              // onClick={handleStartClick}
            >
              <SecurityFirstSvg />
              {/* <Image
                  // @ts-ignore
                  src={securityFirstButton}
                  width={100}
                  height={100}
                  placeholder="blur"
                  // blurDataURL={item.imgUrl}
                  className="w-full h-full rounded-2xl object-contain "
                  alt="banner"
                /> */}
            </span>
            {/* <span
                className="mb-3 text-[rgba(248,248,248,0.70)] cursor-pointer font-rubik"
                onClick={handleStartClick}
                style={{
                  display: "flex",
                  padding: "8px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "32px",
                  border:
                    "1.5px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40))",
                  background:
                    "var(--Surface-Background, rgba(40, 40, 40, 0.70))",
                  // boxShadow: "2px 4px 16px 0px rgba(248, 248, 248, 0.06)",
                  backdropFilter: "blur(50px)",
                }}
              >
                SECURITY FIRST
              </span> */}
            {/* <Button
                size="lg"
                onClick={handleStartClick}
                className="mb-3 cursor-pointer font-mont !rounded-[32px] !border !border-[1.5px] !border-[rgba(255,255,255,0.40)] !shadow-[2px_4px_16px_0px_rgba(248,248,248,0.06)] !backdrop-blur-[50px] !bg-[rgba(40,40,40,0.70)] !px-6 !py-1 flex justify-center items-center gap-[10px] text-[rgba(248,248,248,0.70)]"
                style={{
                  borderColor: "rgba(255, 255, 255, 0.4)",
                }}
              >
                {t("security_first")}
              </Button> */}
          </div>

          <div className="max-w-[719px] 4xl:max-w-[792px] lg:max-w-[700px] sm:max-w-[350px]">
            <h2
              className=" bg-landing-page-headline bg-clip-text text-transparent my-[16px] text-center text-[50px] font-extrabold leading-[56px] opacity-90 font-mont px-[1rem] 4xl:px-[1rem] 4xl:text-[48px] md:text-[32px] sm:text-[28px] sm:leading-[40px] sm:px-[1rem] md:my-[16px]"
              style={{
                color: "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
                letterSpacing: "-1.24px",
                // lineHeight: "75px",
              }}
            >
              {t("total_protection_with_advanced_encryption")}
            </h2>
            <p className="font-mont font-semibold text-[19px] leading-[32px] text-center text-[rgba(248,248,248,0.50)] opacity-80 4xl:text-[20px] md:px-[2rem]">
              {t("your_files_are_secure_and_private")}
            </p>
          </div>

          {/* <p
              className=" font-mont font-normal text-[12px] leading-[1.2] "
              style={{
                color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
                lineHeight: "18px",
              }}
            >
              Your files are secure and private. We do not store any documents
              on our servers.
            </p> */}
        </div>
      </div>

      <div className="py-[32px] sm:py-[6px]">
        <div className="container flex justify-center">
          <Illustration
            img={UsersListFrame}
            className="flex justify-center my-[113px] 4xl:my-[80px] sm:my-[40px] "
            height={364}
            width={1232}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center mt-5 ">
        <div className="flex items-center gap-7">
          <PricingSvg />
        </div>

        <div>
          <h2
            className="bg-landing-page-headline bg-clip-text text-transparent my-[16px] text-center text-[48px] font-extrabold leading-[56px] opacity-90 font-mont w-[960px] px-[1rem] 4xl:px-[1rem] plg:w-[722px] pmd:text-[36px] pmd:px-[0] sm:w-[343px] sm:text-[28px] sm:leading-[40px] sm:px-[2rem] plg:my-[16px] pmd:w-[508px]"
            style={{
              color: "var(--Text-Text-Primary, rgba(248, 248, 248, 0.85))",
              letterSpacing: "-1.24px",
              // lineHeight: "75px",
            }}
          >
            {t("choose_your_simplicity_level")}
          </h2>
        </div>
        {/* <p
            className=" font-mont font-normal text-[12px] leading-[1.2] mb-6"
            style={{
              color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
              lineHeight: "18px",
            }}
          >
            Flexibility and value. Find the option that best suits your needs.
          </p> */}

        <p className="font-mont font-semibold text-[20px] leading-[32px] text-center text-[rgba(248,248,248,0.50)] opacity-80 max-w-[719px] mb-[40px] 4xl:text-[20px] sm:max-w-[372px] sm:px-[2rem] pmd:px-[7rem]">
          {t("flexibility_and_value")}
        </p>

        {/* <ToggleButton /> */}

        {/* <div>
            <h4 className="bg-landing-page-headline bg-clip-text text-transparent font-mont text-[36px] mb-[22px] font-bold leading-[56px] tracking-[-0.72px] text-center plg:hidden">
              Select plan
            </h4>
            <div className="bg-[rgba(18,18,18,0.40)] w-80 h-12 rounded-full flex items-center p-1 relative mb-[48px]">
              <div
                className={`absolute top-0 left-0 h-full w-1/2 bborder-[2.286px] border-white/40 bg-[rgba(248,248,248,0.05)] shadow-[0px_6.095px_6.095px_rgba(0,0,0,0.25)] rounded-full transition-transform duration-300 ease-in-out ${
                  selected === "payasyougo"
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
              />
              <button
                onClick={() => handleSelectPlan("monthly")}
                className={`w-1/2 z-10 text-[rgba(248,248,248,0.95)] focus:outline-none transition-colors duration-300  font-mont font-bold  ${
                  selected === "monthly"
                    ? "text-[rgba(248,248,248,0.95)]"
                    : "text-gray-400"
                }`}
              >
                Monthly Billing
              </button>

              <button
                onClick={() => handleSelectPlan("payasyougo")}
                className={`w-1/2 z-10 text-[rgba(248,248,248,0.95)] focus:outline-none transition-colors duration-300  font-mont font-bold  ${
                  selected === "payasyougo"
                    ? "text-[rgba(248,248,248,0.95)]"
                    : "text-gray-400"
                }`}
              >
                Pay As You Go
              </button>
            </div>
          </div> */}

        <div>
          <button
            className="subscribe-now-btn mb-3"
            onClick={() => router.push("pricing-home")}
          >
            {t("choose_a_plan")}
          </button>
        </div>
      </div>

      <div
        onClick={handleStartNavigateClick}
        className="container  flex grow items-center justify-center pt-[30px] mt-[20px] mb-[40px] plg:overflow-auto plg:scroll-smooth plg:scrollbar-none plg:py-6 plg:before:shrink-0 plg:before:w-8 plg:after:shrink-0 plg:after:w-8 pmd:justify-start pmd:items-start"
      >
        {getPlans &&
          getPlans[plan]
            ?.slice(0, 1)
            .map((x: any) => (
              <AdditionalPackage
                item={x}
                package_plan={getPlans[plan]}
                currentPlan={currentPlan}
                plan={plan}
                key={x.id}
                featuresPrice={featuresPrice}
              />
            ))}
        {getPlans &&
          getPlans[plan]
            ?.slice(1, 3)
            .map((x: any) => (
              <Package
                item={x}
                package_plan={getPlans[plan]}
                currentPlan={currentPlan}
                plan={plan}
                key={x.id}
                featuresPrice={featuresPrice}
              />
            ))}

        {/* <div
            className={`mt-auto relative flex  rounded-3xl
           min-h-[200px] min-w-[380px] max-w-[400px]
          lsm:min-h-[350px] lsm:min-w-[700px] lsm:max-w-[800px]  
          llg:min-h-[406px] llg:min-w-[850px] llg:max-w-[1000px] 
          lxl:min-h-[404px] lxl:min-w-[900px] lxl:max-w-[900px] 
          l2xl:min-h-[486px] l2xl:min-w-[850px] l2xl:max-w-[900px] 
          l3xl:min-h-[504px] l3xl:min-w-[1000px] l3xl:max-w-[1000px] 
          `}
            style={{
              // borderRadius: "10px",
              background: `var(--Background-bg-10, url(${
                PricingContent.src
              }) transparent 100%/ ${"100% 100% no-repeat"} no-repeat)`,
            }}
          ></div> */}
        {/* <Illustration
            img={PricingContent}
            className="flex justify-center max-w-[1200px] lmd:h-[420px] rounded-3xl overflow-hidden"
            height={420}
            width={1200}
          /> */}
      </div>

      <FaqSection
        title={t("frequently_asked_questions")}
        description={t(
          "contact_us_via_support_if_you_have_any_more_questions."
        )}
        buttonLabel={t("contact_us")}
        faqs={FAQS}
      />

      <FooterBanner
        title={t("transform_your_documentstoday")}
        description={t("professional_fast_and_secure_translation")}
        buttonLabel={t("create_an_account")}
        buttonURL="sd"
        image={centeredTaskSvg}
      />
    </>
  );
};

export default Banner;
