import React from "react";
import { Illustration } from "modules/FeaturesModule/Component/Illustration";
import videoFrame2 from "../public/legal-Images/webpImages/videoFrame2.webp";
import centerdimg from "../public/doc-processing-images/centered-task-list.webp";
import QuestionsLegal from "modules/FeaturesModule/Component/QuestionsLegal";
import Image from "next/image";
import VideoPoster from "../public/doc-processing-images/video-poster.webp";

type GridBlocksProps = {
  title: string;
  badge?: string;
  blocks: { image: any; title: string; description: string }[];
  showBlockNumber?: boolean;
};

const GridBlocks = ({
  title,
  badge,
  blocks,
  showBlockNumber,
}: GridBlocksProps) => {
  return (
    <div className="container mt-20">
      <div>
        <div className="text-center">
          {/* <h5 className="h5 uppercase text-xs font-bold text-lightGray-100/50 border-2 border-solid border-lightGray-100/5 py-[5px] px-4 inline-block rounded-2xl mb-15 lg:mb-10 md:mb-7">{badge}</h5> */}
          <button className="subscribe-now-btn mb-3 pointer-events-none">{badge}</button>
          <h2 className="bg-landing-page-headline bg-clip-text text-transparent  text-[48px]  mt-3 lg:text-[30px] md:text-[25px] font-extrabold leading-[56px] lg:leading-[40px]  md:leading-[32px] opacity-90 font-mont tracking-tighter mb-15 lg:mb-10 md:mb-7">
            {title}
          </h2>
        </div>
        <div className="grid grid-cols-3 mb-20 gap-5 lg:grid-cols-2 md:grid-cols-1 pb-4 lg:mb-12 md:mb-7">
          {blocks.map((item, index) => {
            return (
              <div className="" key={index}>
                <div className="use-cases-box h-100">
                  {showBlockNumber && (
                    <span className="text-sm font-bold py-4 px-8 rounded-[32px] inline-flex items-center justify-center bg-step-count shadow-step-count backdrop-blur-[50px] text-white absolute -top-7 left-1/2 -translate-x-1/2 z-1">
                      {index + 1}
                    </span>
                  )}
                  <div className="use-cases-box-img">
                    <Image src={item.image} alt={item.title} />
                  </div>
                  <div>
                    <h6
                      className="!font-mont font-[700] text-[22px] leading-[1.2] mb-2"
                      style={{
                        color:
                          "var(--Text-Secondary, rgba(248, 248, 248, 0.70))",
                      }}
                    >
                      {item.title}
                    </h6>
                    <p className="font-mont font-semibold text-[16px] leading-[24px]  m text-[rgba(248,248,248,0.50)] opacity-80 ">
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GridBlocks;
